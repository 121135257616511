import Layout from "../../../components/Layout/Layout";
import TermsNav from "../TermsNav/TermsNav";
import styles from "./Service.module.css";

export default function Service() {
  return (
    <Layout>
      <main className={`${styles.terms_service} inner`}>
        <TermsNav />
        <dl>
          <dt>제 1조 (목적)</dt>
          <dd>
            본 약관은 주식회사 엔아이시스템 사이트(이하 "당 사이트")가 제공하는
            모든 서비스(이하 "서비스")의 이용조건 및 절차, 이용자와 당 사이트의
            권리, 의무, 책임사항과 기타 필요한 사항을 규정함을 목적으로 합니다.
          </dd>
        </dl>
        <dl>
          <dt>제 2조 (약관의 명시와 개정)</dt>
          <dd>
            당 사이트는 이 약관의 내용과 주소지, 관리자의 성명, 개인정보
            보호책임자의 성명, 연락처(전화, 팩스, 전자우편 주소 등) 등을
            이용자가 알 수 있도록 당 사이트의 초기 서비스화면(전면)에
            게시합니다.당 사이트는 약관의 규제에 관한 법률, 전자거래기본법,
            전자서명법, 개인정보보호법 등 관련법을 위배하지 않는 범위에서 이
            약관을 개정할 수 있습니다.당 사이트가 약관을 개정할 경우에는
            적용일자 및 개정사유를 명시하여 현행약관과 함께 당 사이트의
            초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.당
            사이트는 이용자가 본 약관 내용에 동의하는 것을 조건으로 이용자에게
            서비스를 제공할 것이며, 이용자가 본 약관의 내용에 동의하는 경우, 당
            사이트의 서비스 제공 행위 및 이용자의 서비스 사용 행위에는 본 약관이
            우선적으로 적용될 것입니다.이 약관에 동의하는 것은 정기적으로 웹을
            방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된
            약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는 당 사이트에서
            책임지지 않습니다.본 약관에 명시되지 않은 사항은 전기통신기본법,
            전기통신사업법, 방송통신심의위원회 정보통신에 관한 심의규정,
            저작권법 및 기타 관련 법령의 규정에 의합니다.
          </dd>
        </dl>
        <dl>
          <dt>제 3조 (용어의 정의)</dt>
          <dd>
            본 약관에서 사용하는 용어의 정의는 다음과 같습니다. 이용자 : 본
            약관에 따라 당 사이트가 제공하는 서비스를 받는 자. 이용계약 : 서비스
            이용과 관련하여 당 사이트와 이용자 간에 체결하는 계약을 말합니다.신
            청 : 당 사이트가 제공하는 신청서 양식에 해당 정보를 기입하고, 본
            약관에 동의하여 서비스 이용계약을 완료시키는 행위본 약관에서
            정의하지 않은 용어는 개별서비스에 대한 별도약관 및 이용규정에서
            정의합니다.
          </dd>
        </dl>
        <dl>
          <dt>제 4조 (이용계약의 성립)</dt>
          <dd>
            이용계약은 이용자가 본 이용약관 내용에 대한 동의와 이용신청에 대하여
            당 사이트의 이용승낙으로 성립합니다.본 이용약관에 대한 동의는
            이용신청 당시 해당 당 사이트의 '동의함' 또는 신청(등록)버튼을
            누름으로써 의사표시를 합니다.
          </dd>
        </dl>
        <dl>
          <dt>제 5조 (개인정보 사용에 대한 동의)</dt>
          <dd>
            이용자의 개인정보에 대해서는 당 사이트의 개인정보 처리방침이
            적용됩니다. 다만, 당 사이트 이외의 링크된 사이트에서는 당 사이트의
            개인정보 처리방침이 적용되지 않습니다.
          </dd>
        </dl>
        <dl>
          <dt>제 6조 (사용자의 정보 보안)</dt>
          <dd>
            이용자가 당 사이트 서비스 신청 절차를 완료하는 순간부터 이용자는
            입력한 정보의 비밀을 유지할 책임이 있습니다.이용자가 입력한 정보에
            관한 모든 관리의 책임은 이용자에게 있으며, 이용자가 입력한 정보가
            부정하게 사용되었다는 사실을 발견한 경우에는 즉시 당 사이트에
            알려주셔야 합니다. 알리지 않음으로 인해 발생하는 모든 책임은 이용자
            본인에게 있습니다.
          </dd>
        </dl>
        <dl>
          <dt>제 7조 (서비스 이용시간)</dt>
          <dd>
            서비스 이용시간은 당 사이트의 업무상 또는 기술상 특별한 지장이 없는
            한 연중무휴, 1일 24시간을 원칙으로 합니다.제1항의 이용시간 중
            정기점검 등의 필요로 인하여 당 사이트가 정한 날 또는 시간은 예외로
            합니다.
          </dd>
        </dl>
        <dl>
          <dt>제 8조 (서비스의 중지 및 중지에 대한 공지)</dt>
          <dd>
            당 사이트 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의
            내용이 국가의 비상사태, 정전, 당 사이트의 관리 범위 외의 서비스 설비
            장애 및 기타 불가항력에 의하여 보관되지 못하였거나 삭제된 경우,
            전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 당
            사이트는 관련 책임을 부담하지 아니합니다.당 사이트가 정상적인 서비스
            제공의 어려움으로 인하여 일시적으로 서비스를 중지하여야 할 경우에는
            서비스 중지 1주일 전에 홈페이지에 서비스 중지사유 및 일시를 공지한
            후 서비스를 중지할 수 있으며, 이 기간 동안 이용자가 공지내용을
            인지하지 못한 데 대하여 당 사이트는 책임을 부담하지 아니합니다.
            부득이한 사정이 있을 경우 위 사전 공지기간은 감축되거나 생략될 수
            있습니다. 또한 위 서비스 중지에 의하여 본 서비스에 보관되거나 전송된
            메시지 및 기타 통신 메시지 등의 내용이 보관되지 못하였거나 삭제된
            경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에
            대하여도 당 사이트는 책임을 부담하지 아니합니다.당 사이트의 사정으로
            서비스를 영구적으로 중단하여야 할 경우에는 제 2 항에 의거합니다.
            다만, 이 경우 사전 공지기간은 1개월로 합니다.당 사이트는 사전 공지
            후 서비스를 일시적으로 수정, 변경 및 중단할 수 있으며, 이에 대하여
            이용자 또는 제3자에게 어떠한 책임도 부담하지 아니합니다.당 사이트는
            긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로 인하여 예고없이
            일시적으로 서비스를 중단할 수 있으며, 새로운 서비스로의 교체 등 당
            사이트가 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스를
            완전히 중단할 수 있습니다.당 사이트는 국가비상사태, 정전, 서비스
            설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이
            불가능할 경우, 서비스의 전부 또는 일부를 제한하거나 중지할 수
            있습니다. 다만 이 경우 그 사유 및 기간 등을 이용자에게 사전 또는
            사후에 공지합니다.당 사이트는 당 사이트가 통제할 수 없는 사유로 인한
            서비스중단의 경우(시스템관리자의 고의•과실 없는 디스크장애,
            시스템다운 등)에 사전통지가 불가능하며 타인(PC통신회사,
            기간통신사업자 등)의 고의•과실로 인한 시스템중단 등의 경우에는
            통지하지 않습니다.당 사이트는 서비스를 특정범위로 분할하여 각
            범위별로 이용가능시간을 별도로 지정할 수 있습니다. 다만 이 경우 그
            내용을 공지합니다.당 사이트는 이용자가 본 약관의 내용에 위배되는
            행동을 한 경우, 임의로 서비스 사용을 제한 및 중지하거나 이용자의
            동의 없이 이용계약을 해지할 수 있습니다. 이 경우 당 사이트는 위
            이용자의 접속을 금지할 수 있습니다.
          </dd>
        </dl>
        <dl>
          <dt>제 9조 (정보 제공 및 홍보물 게재)</dt>
          <dd>
            당 사이트는 서비스를 운영함에 있어서 각종 정보를 홈페이지에 게재하는
            방법, 전자우편이나 서신우편 발송 등으로 이용자에게 제공할 수
            있습니다.당 사이트는 서비스에 적절하다고 판단되거나 공익성이 있는
            홍보물을 게재할 수 있습니다.이용자가 게시한 게시물의 내용에 대한
            권리는 해당 게시물을 등록한 이용자에게 있습니다.당 사이트는 게시된
            내용을 사전 통지 없이 편집, 이동할 수 있는 권리를 보유하며, 다음의
            경우 사전 통지 없이 삭제할 수 있습니다.①욕설·특정인에 대한 비방 등
            인권침해 소지가 있는 경우②성인 게시물 등 공공질서 및 미풍양속에
            위반되는 내용인 경우③악의적으로 허위사실을 유포한 경우④제3자의
            저작권 등 기타 권리를 침해하는 내용인 경우⑤상업적 목적이 있는
            경우⑥동일한 내용의 반복 게시⑦게시자가 자신이 등록한 게시물의 삭제를
            요구한 경우⑧기타 홈페이지의 정상적인 운영을 저해하는 표현 또는
            내용이용자의 게시물이 타인의 저작권을 침해함으로써 발생하는 민,
            형사상의 책임은 전적으로 해당 게시물을 등록한 이용자가 부담하여야
            합니다.
          </dd>
        </dl>
        <dl>
          <dt>제 10조 (서비스 이용제한)</dt>
          <dd>
            이용자가 제공하는 정보의 내용이 허위인 것으로 판명되거나, 허위가
            있다고 의심할 만한 합리적인 사유가 발생할 경우 당 사이트는 이용자의
            본 서비스 사용을 일부 또는 전부 중지할 수 있으며, 이로 인해 발생하는
            불이익에 대해 책임을 부담하지 아니합니다.당 사이트는 이용자가 본
            약관 제15조(회원의 의무)등 본 약관의 내용에 위배되는 행동을 한 경우,
            임의로 서비스 사용을 제한 및 중지할 수 있습니다. 이 경우 당 사이트는
            이용자의 접속을 금지할 수 있습니다.
          </dd>
        </dl>
        <dl>
          <dt>제 12조 (당 사이트의 의무)</dt>
          <dd>
            당 사이트는 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를
            하지 않으며, 지속적·안정적으로 서비스를 제공하기 위해 노력할 의무가
            있습니다.당 사이트는 이용자의 개인 신상 정보를 본인의 승낙 없이
            타인에게 누설, 배포하지 않습니다. 다만, 관계법령에 의하여 관계
            국가기관 등의 요구가 있는 경우에는 그러하지 아니합니다.당 사이트는
            이용자가 안전하게 당 사이트서비스를 이용할 수 있도록 이용자의
            개인정보 보호를 위한 보안시스템을 갖추어야 합니다.당 사이트는
            이용자의 귀책사유로 인한 서비스 이용 장애에 대하여 책임을 지지
            않습니다.
          </dd>
        </dl>
        <dl>
          <dt>제 13조 (이용자의 의무)</dt>
          <dd>
            이용자는 당 사이트의 사전 승낙 없이 서비스를 이용하여 어떠한
            영리행위도 할 수 없습니다.이용자는 당 사이트 서비스를 이용하여 얻은
            정보를 당 사이트의 사전승낙 없이 복사, 복제, 변경, 번역, 출판, 방송
            기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.이용자는
            당 사이트 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안
            됩니다.①다른 이용자의 개인정보를 부정 사용하는 행위②저속, 음란,
            모욕적, 위협적이거나 타인의 사생활을 침해할 수 있는 내용을 전송,
            게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위③서비스를
            통하여 전송된 내용의 출처를 위장하는 행위④법률, 계약에 의해 이용할
            수 없는 내용을 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는
            행위⑤타인의 특허, 상표, 영업비밀, 저작권, 기타 지적재산권을 침해하는
            내용을 게시, 게재, 전자우편 또는 기타의 방법으로 전송하는 행위⑥당
            사이트의 승인을 받지 아니한 광고, 판촉물, 스팸메일, 행운의 편지,
            피라미드 조직 기타 다른 형태의 권유를 게시, 게재, 전자우편 또는
            기타의 방법으로 전송하는 행위⑦다른 사용자의 개인정보를 수집 또는
            저장하는 행위⑧범죄행위를 목적으로 하거나 기타 범죄행위와 관련된
            행위⑨선량한 풍속, 기타 사회질서를 해하는 행위⑩타인의 명예를
            훼손하거나 모욕하는 행위⑪타인의 지적재산권 등의 권리를 침해하는
            행위⑫해킹행위 또는 컴퓨터바이러스의 유포행위⑬타인의 의사에 반하여
            광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위⑭서비스의
            안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위⑮당 사이트에
            게시된 정보의 변경
          </dd>
        </dl>
        <dl>
          <dt>제 14조 (당 사이트의 소유권)</dt>
          <dd>
            당 사이트가 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크,
            로고, 디자인, 서비스명칭, 정보 및 상표 등과 관련된 지적재산권 및
            기타 권리는 당 사이트에 소유권이 있습니다.이용자는 당 사이트가
            명시적으로 승인한 경우를 제외하고는 전항의 소정의 각 재산에 대한
            전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 양도,
            재라이센스, 담보권 설정 행위, 상업적 이용 행위를 할 수 없으며,
            제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.
          </dd>
        </dl>
        <dl>
          <dt>제 15조 (손해배상)</dt>
          <dd>
            당 사이트는 무료로 제공되는 서비스와 관련하여 이용자에게 어떠한
            손해가 발생하더라도 당 사이트가 고의로 행한 범죄행위를 제외하고는
            이에 대하여 책임을 부담하지 아니합니다.
          </dd>
        </dl>
        <dl>
          <dt>제 16조 (면책조항)</dt>
          <dd>
            당 사이트는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여
            서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이
            면제됩니다.당 사이트는 기간통신 사업자가 전기통신 서비스를
            중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이
            면제됩니다.당 사이트는 서비스용 설비의 보수, 교체, 정기점검, 공사 등
            부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.당 사이트는
            이용자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 이용자가
            신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우
            책임을 지지 않습니다.당 사이트는 서비스에 표출된 어떠한 의견이나
            정보에 대해 확신이나 대표할 의무가 없으며 회원이나 제3자에 의해
            표출된 의견을 승인하거나 반대하거나 수정하지 않습니다. 당 사이트는
            어떠한 경우라도 이용자가 서비스에 담긴 정보에 의존해 얻은 이득이나
            입은 손해에 대해 책임이 없습니다.당 사이트는 이용자간 또는 이용자와
            제3자간에 서비스를 매개로 하여 물품거래 혹은 금전적 거래 등과
            관련하여 어떠한 책임도 부담하지 아니하고, 이용자가 서비스의 이용과
            관련하여 기대하는 이익에 관하여 책임을 부담하지 않습니다.당 사이트는
            이용자가 서비스를 이용하여 기대하는 손익이나 서비스를 통하여 얻은
            자료로 인한 손해에 관하여 책임을 지지 않으며, 이용자가 본 서비스에
            게재한 정보, 자료, 사실의 신뢰도 등 내용에 관하여는 책임을 지지
            않습니다.당 사이트는 서비스 이용과 관련하여 이용자에게 발생한 손해
            중 이용자의 고의, 과실에 의한 손해에 대하여 책임을 부담하지
            아니합니다.당 사이트는 당 사이트가 제공한 서비스가 아닌 이용자 또는
            기타 유관기관이 제공하는 서비스의 내용상의 정확성, 완전성 및 질에
            대하여 보장하지 않습니다. 따라서 당 사이트는 이용자가 위 내용을
            이용함으로 인하여 입게 된 모든 종류의 손실이나 손해에 대하여 책임을
            부담하지 아니합니다. 또한 당 사이트는 이용자가 서비스를 이용하며 타
            이용자로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지
            않습니다.
          </dd>
        </dl>
        <dl>
          <dt>제 17조 (관할법원)</dt>
          <dd>
            본 서비스 이용과 관련하여 발생한 분쟁에 대해 소송이 제기될 경우
            서울지방법원 본원을 전속적 관할 법원으로 합니다.
          </dd>
        </dl>
        <dl>
          <dt>부칙</dt>
          <dd>이 약관은 2023년 7월 1일부터 적용됩니다.</dd>
        </dl>
      </main>
    </Layout>
  );
}
