import Layout from "../../../components/Layout/Layout";
import PageHeader from "../../../components/PageHeader/PageHeader";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import styles from "./History.module.css";

export default function AboutHistory() {
  return (
    <Layout>
      <PageHeader
        gnbname="about"
        title="연혁"
        destription="전문성을 바탕으로 최적화 컨설팅을 진행하며 최고의 서비스를 제공합니다."
      />
      <main className={styles.about_history}>
        <section>
          <SectionTitle title="회사 연혁" />
          <CompanyHistory />
          <SectionTitle title="대표 연혁" />
          <CEOHistory />
        </section>
      </main>
    </Layout>
  );
}

function CompanyHistory() {
  return (
    <ul className={`${styles.year_area} inner`}>
      <li>
        <p className={`${styles.year} ${styles.first}`}>2023</p>
        <dl className={styles.month}>
          <dt>05월</dt>
          <div>
            <dd>피앤씨솔루션 네트워크 컨설팅/구축</dd>
            <dd>인터엠 Network Switch 유지보수 체결</dd>
          </div>
        </dl>
        <dl className={styles.month}>
          <dt>04월</dt>
          <div>
            <dd>제주항공 운항교육시스템 UPS 납품/구축</dd>
            <dd>한국환경과학원 Network Switch 유지보수 체결</dd>
          </div>
        </dl>
        <dl className={styles.month}>
          <dt>03월</dt>
          <div>
            <dd>SnC시스템즈 전산실 이전</dd>
            <dd>서진안테나 사무실 네트워크 구축/공사</dd>
          </div>
        </dl>
        <dl className={styles.month}>
          <dt>01월</dt>
          <div>
            <dd>베트남 Seojin VINA, AUTO, Vietnam 지점 Network Switch 납품/구축</dd>
          </div>
        </dl>
      </li>
      <li>
        <p className={styles.year}>2022</p>
        <dl className={styles.month}>
          <dt>10월</dt>
          <div>
            <dd>교보증권 IDC 이전 프로젝트 참여</dd>
          </div>
        </dl>
        <dl className={styles.month}>
          <dt>07월</dt>
          <div>
            <dd>엑셈 보안장비 납품/구축/컨설팅</dd>
            <dd>클라우드메이트 사무실 네트워크 구축</dd>
          </div>
        </dl>
        <dl className={styles.month}>
          <dt>06월</dt>
          <div>
            <dd>한국자동차산업협회 유지보수 체결</dd>
          </div>
        </dl>
        <dl className={styles.month}>
          <dt>04월</dt>
          <div>
            <dd>서진시스템 본사/베트남 지점 백신 납품</dd>
            <dd>서진시스템 보안/네트워크/서버 유지보수 체결</dd>
            <dd>콘텐츠브릿지 IDC BackBone Switch 유지보수 체결</dd>
            <dd>인도네시아 오로라월드 네트워크 유지보수 체결</dd>
            <dd>현대자동차 차세대 고객센터 Network Switch 납품/구축</dd>
          </div>
        </dl>
        <dl className={styles.month}>
          <dt>02월</dt>
          <div>
            <dd>충남광역증진센터 보안장비 납품/구축</dd>
          </div>
        </dl>
      </li>
      <li>
        <p className={styles.year}>2021</p>
        <dl className={`${styles.month} ${styles.last}`}>
          <dt>12월</dt>
          <div>
            <dd>한일튜브(주) Network Switch 납품 및 유지보수 체결</dd>
            <dd>교원 콜센터 Network 유지보수 체결</dd>
            <dd>(주)엔아이시스템 법인 설립</dd>
          </div>
        </dl>
      </li>
    </ul>
  );
}

function CEOHistory() {
  return (
    <ul className={`${styles.year_area} inner`}>
      <li>
        <p className={`${styles.year} ${styles.first}`}>2020</p>
        <dl className={styles.month}>
          <dt>01월</dt>
          <div>
            <dd>IDC BackBone Switch 교체 및 이중화 구축</dd>
          </div>
        </dl>
      </li>
      <li>
        <p className={styles.year}>2019</p>
        <dl className={styles.month}>
          <dt>1 1월</dt>
          <div>
            <dd>태국 타이이스타 네트워크 구축</dd>
          </div>
        </dl>
        <dl className={styles.month}>
          <dt>06월</dt>
          <div>
            <dd>NIPA GPU사업 네트워크 설계 구축 운용&유지보수</dd>
          </div>
        </dl>
      </li>
      <li>
        <p className={styles.year}>2014</p>
        <dl className={styles.month}>
          <dt>09월</dt>
          <div>
            <dd>이스타항공 항공시스템 인프라 Cloud(AWS, Azure) 구축 및 이관</dd>
            <dd>
              AWS DirectConnect / Azure ExpressRoute 컨설팅 및 설계 구축,
              Multi-Cloud 연동
            </dd>
          </div>
        </dl>
      </li>
      <li>
        <p className={styles.year}>2012</p>
        <dl className={styles.month}>
          <dt>02월</dt>
          <div>
            <dd>콘텐츠브릿지 MSP기술지원팀 팀장 수행</dd>
            <dd>KT-IDC(강남,분당,목동) 네트워크/서버 유지보수</dd>
          </div>
        </dl>
      </li>
      <li>
        <p className={styles.year}>2010</p>
        <dl className={styles.month}>
          <dt>02월</dt>
          <div>
            <dd>이스타항공 보안/네트워크 유지보수</dd>
            <dd>호주 시드니(Navitaire) 항공망 구축, 연동 및 관리</dd>
            <dd>국내/해외 지점 신규 구축 지원</dd>
          </div>
        </dl>
      </li>
      <li>
        <p className={styles.year}>2007</p>
        <dl className={`${styles.month} ${styles.last}`}>
          <dt>06월</dt>
          <div>
            <dd>기업 및 관공서 네트워크 유지보수</dd>
          </div>
        </dl>
      </li>
    </ul>
  );
}
